import React, { Component } from 'react';
import  $ from "jquery";
import Carousel from 'react-gallery-carousel';
import './BaseUrl';
import 'react-gallery-carousel/dist/index.css';
import {Link} from 'react-router-dom';
import Slider from "react-slick";



class Conference_Centre extends Component{
  constructor(){
    super();
    this.state={
      base_url:global.BaseUrl,
      assets_url:global.AssetsUrl,
      banner_img:[],
      relatedoc:[],
    }
  }

    componentDidMount() {
        <script> 
        $(document).ready(function(){
          $("#flip1").click(function(){
            $("#panel1").slideToggle("show");
          })
        });

        $(document).ready(function(){
          $("#flip2").click(function(){
            $("#panel2").slideToggle("show");
          })
        });

         $(document).ready(function(){
          $("#flip3").click(function(){
            $("#panel3").slideToggle("show");
          })
        });
        </script>

fetch(`${this.state.base_url}/getfilesbyid/8`).then((resp)=>{
  resp.json().then((result)=>{
    this.setState({ relatedoc: result});        
  })
});
fetch(`${this.state.base_url}/getBannerById/8`).then((resp)=>{
  resp.json().then((result)=>{
    //  console.log(result);
    this.setState({ banner_img: result.banner_images});
    // this.setState({ headingType: result.data});           
  })
});
    
    }

    render(){
      const {relatedoc,banner_img} = this.state;
      var settings = {
        className: "",
        dots: false,
        prevArrow: false,
        nextArrow: false,arrows: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        speed: 4000,
        autoplaySpeed: 0,
        pauseOnHover: false,
        cssEase: 'linear',
        adaptiveHeight: true,

        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
        
      };

      
        return(

            <>
          

      
<div className="container-fluid slider_top">
            <div className="row">
              <div className="col-md-4 col-12">
                <img src="./assets/images/banner/cateringmenu1.jpg" alt="" className='bnr_img_30 padding_set' />
              </div>
              <div className="col-md-4 col-12">
                <img src="./assets/images/banner/cateringmenu2.jpg" alt="" className='bnr_img_30 padding_set mobile_hide' />
              </div>
              <div className="col-md-4 col-12">
                <img src="./assets/images/banner/cateringmenu3.jpg" alt="" className='bnr_img_30 padding_set mobile_hide' />
              </div>
            </div>
      </div>

      <section className="counter-section-03 padding-top-50 padding-bottom-50 mt-4" style={{padding:'0px 0px 0px 0px'}}>
    <div className="container" > 
        <div className="row " >
           
           <div className="col-md-12 mt-4" style={{color:'#000',fontSize:'16px'}}>
           <div className="col-md-12 col-12">
                <p className="table_content">Provision of food and beverages is an important meeting component. Our Client Center provides a seasonal menu and range of
                  services to includes healthy and Chinese breakfasts, plated lunches and dinners including Chinese and Western sets, premium sandwiches
                  and hot or cold buffets. Event Chef Stations including Peking Crepe, Sports and Antipasto Station. Morning and afternoon event
                  refreshments. 
                  </p>
                
            </div>
           
           </div>
        </div>
    </div>
</section>

<section className="counter-section-03 padding-top-50 padding-bottom-50 mt-4" style={{padding:'0px 0px 0px 0px'}}>
    <div className="container" > 
        <div className="row ">
           
           <div className="col-md-12 mt-4" style={{color:'#000',fontSize:'16px'}}>
           <div className="col-md-12 col-12">
                <ul>
                <li className="text_justify">Ready to reserve catering? Book now through <a href='https://openspace.gaiacloud.jpmchase.net/dashboard' target='_blank' className='top_heading2'>Go/Openspace</a ></li>
                <li className="text_justify">Selections of premium hot and cold canapés and wide range of wines for cocktail parties. For full menu options, please Click here for the  <a href="./assets/images/coming-soon.pdf" target='_blank' className='top_heading2'>The Menu</a ></li>                
                </ul>

                <p className="table_content">We remain committed to the highest standard of food safety, hygiene and sanitation with rigorous hand washing, sanitation of surfaces, daily team health checks and promoting social distancing. We have made some modifications to our menu so that we may continue to keep everyone safe and healthy, including individually packaged meals and recommended safety protocols.</p>
            </div>

            <div className="col-md-12 col-12">
                <p className="table_heading ">Please note the following:</p>
                <ul>
                  <li className='text_justify'>Food and Beverages Service is available from 0800 — 1800, Monday — Friday. Dinner or event outside the hours above must be
                        booked in advance and is subject to approval of Client Center Manager and availability of resources.
                  </li>
                  <li className='text_justify'>Food must be ordered no later than 2 business days before the event date.</li>
                  <li className='text_justify'>Outside food and beverages are not permitted in the Client Center.</li>
                </ul>
            </div>

           
           </div>
        </div>
    </div>
</section>
{/* <section className="counter-section-03 padding-top-50 padding-bottom-75 mt-4" >
    <div className="container">
        <div className="row">
           <div className="col-md-12 mt-4">
             
           <div className="col-md-12 col-12">
          
                <p className="table_heading ">Your Safety is Our Top Priority</p>
                <p className="table_content">We remain committed to the highest standard of food safety, hygiene and sanitation with rigorous hand washing, sanitizing surfaces, daily team health checks and promoting social distancing. We have made some modifications to our menu so that we may continue to keep everyone safe and healthy, including individually packaged meals and recommended safety protocols.</p>
                
            </div>

            <div className="col-md-12 col-12">
                <p className="table_heading ">We're Here to Help!</p>
                <ul>
                  <li className='table_content' style={{margin:'0px 0px 10px 0px'}}>Allergen information available</li>
                  <li className='table_content'>For any other inquiries, do not hesitate to contact your Amenity Services Team</li>
                </ul>
            </div>
           

           
           </div>
        </div>

       
    </div>
</section> */}
   


{
           relatedoc.length == 0 ? null :  
            <div className="row bg-color padding-bottom-100 related_doc">
           <div className="container">
           <h3 className="res-title" style={{color:'#000',marginTop:'40px'}}>Related Document</h3>
           <div className="row mb-3">
           <div className="col-12">
           { relatedoc.map(doc=>
             
             <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 ">
            <a href={`${this.state.assets_url}/uploads/${doc.file_name}`} target="_blank"><p class="des realtedoc">{doc.file_title}</p></a>
             </div>
               ) }
           </div>
           </div> 
          
           </div>
           </div> 
         }
         
            </>
        );
    }
}

export default Conference_Centre;
